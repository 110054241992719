<template>
    <footer-head :headRowAttrs="{ align: 'center', justify: 'center' }" :headLeftAttrs="{ cols: '12', md: '', order: '2', 'order-md': '1' }" :headRightAttrs="{ cols: '12', md: 'auto', order: '1', 'order-md': '2', class: 'd-flex' }" class="footer-head--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </footer-head>
</template>

<script>
import FooterHead from "@/sets/styles/footers/footer-head.vue";

export default {
    props: {},
    components: {
        FooterHead,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer-head--basic {
    padding: 14px 0;
    border-top: 1px solid var(--v-grey-lighten4);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-head--basic {
        padding: 20px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
