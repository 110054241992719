import orders from "./orders";
import giftcards from "./giftcards";
import * as setting from "./setting";
import charges from "./charges";
import collection from "./collection";

export default {
    orders,
    giftcards,
    ...setting,
    collection,
    charges
}
