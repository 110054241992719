const digitLengthOf = (value) => value.replaceAll(/\D/g, "").length;

export const rules = {
    username: [(value) => (/^[a-zA-Z0-9]{4,12}$/.test(value)) || "4~12 영문·숫자 조합, 가입 후 아이디 변경은 불가능함을 알려드립니다."],

    password: [(value) => /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{10,20}$/.test(value) || "영문·숫자·특수문자 조합으로 10자리 이상 20자리 이하입니다"],

    passwordModify: [(value) => !value || /[~!@#$%^&*()_+|<>?:{}]/.test(value) || "비밀번호에 특수문자를 넣어주세요", (value) => !value || /[a-zA-Z]/.test(value) || "비밀번호에 영문을 넣어주세요", (value) => !value || /[0-9]/.test(value) || "비밀번호에 숫자를 넣어주세요", (value) => !value || (8 <= value.length && value.length <= 16) || "비밀번호를 8 ~ 16자 사이로 작성해주세요"],

    name: [(value) => (value && 1 < value?.length) || "이름을 입력해주세요"],

    email: [(value) => (value && /[^@]+@[^@]+\.[^@]+/.test(value)) || "이메일을 입력해주세요"],

    phone: [(value) => (value && [10, 11].includes(digitLengthOf(value))) || "연락처를 입력해주세요"],

    promoterCode: [(value) => !!value || "추천인코드를 입력해주세요"],
    businessNo: [(value) => !!value || "사업자등록번호를 입력해주세요"],
    businessLicense: [(value) => !!value || "사업자등록증을 입력해주세요"],
    companyName: [(value) => !!value || "기업명을 입력해주세요"],
    companyCeo: [(value) => !!value || "대표자 성명을 입력해주세요"],
};
