var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-header-fixed', [_c('u-header-body-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', [_vm._v("비즈몰")])];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('div', {
          staticClass: "px-lg-20px"
        }, [_c('u-gnb-underline', {
          attrs: {
            "gnbName": "gnb",
            "lnbTag": "u-lnb-topline-border"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_vm.accessToken ? _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('txt-primary', {
          staticClass: "txt--dark font-weight-medium"
        }, [_vm._v("내 포인트 " + _vm._s(_vm.point.format()) + "원")])], 1) : _vm._e(), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('user-items-primary', {
          staticClass: "font-weight-medium"
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', _vm._b({
          attrs: {
            "to": "/buying"
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("상품구매하기")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }