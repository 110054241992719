import axios from "@/plugins/axios";

let url = "/api/v1/users";

export default {
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    certify(data) {
        return axios.post(`${url}/certify`, data).then((result) => result.data);
    },
    recovery(data) {
        return axios.post(`${url}/recovery`, data).then((result) => result.data);
    },
    otp(data) {
        return axios.post(`${url}/otp`, data).then((result) => result.data);
    },

    files: {
        post(_user, file){
            var formData = new FormData(); formData.append("image", file);
            var headers = { "Content-Type": "multipart/formdata" };
            return axios.post(`${url}/${_user}/files`, formData, { headers }).then(result => result.data);
        },
        delete(_user){
            return axios.delete(`${url}/${_user}/files`).then(result => result.data);
        }
    }
};
