<template>
    <client-page class="app--main">
        <div class="main-visual">
            <v-container>
                <v-row align="center" justify="space-between">
                    <v-col cols="12" md="5" lg="auto" order-md="2">
                        <v-img :max-width="$vuetify.breakpoint.mdAndUp ? 582 : 300" src="/images/main/mainvisual-img.png" class="mx-auto mb-20px mb-md-0" />
                    </v-col>
                    <v-col cols="12" md="7" lg="" order-md="1" class="text-center text-md-left">
                        <div class="mb-12px mb-md-14px">
                            <v-img src="/images/daehanbooks-logo.png" style="width: 120px; height: 30px" class="mx-auto mx-md-0" />
                            <!-- <logo-primary class="mx-auto mx-md-0" /> -->
                        </div>
                        <div class="mb-16px mb-md-20px mb-lg-30px">
                            <tit-primary titSize="font-size-30 font-size-lg-56"><span class="primary--text">비즈몰</span> 공식오픈</tit-primary>
                        </div>
                        <div>
                            <txt-primary txtSize="font-size-16 font-size-lg-24" class="txt--dark line-height-15">
                                B2B 사업자를 위한 대한문고 비즈몰이 오픈했습니다.<br />
                                더 쉽고 편리하게 기업을 홍보를 하고 직원들에게 복지를 선물하세요.
                            </txt-primary>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <page-section-primary>
            <template #pageSectionFoot>
                <page-section-primary class="page-section--sm py-0">
                    <v-row align="center" class="ma-lg-n20px">
                        <v-col cols="12" md="6" order-md="2" class="pa-lg-20px">
                            <v-img :aspect-ratio="580 / 340" src="/images/main/main-img.jpg" />
                        </v-col>
                        <v-col cols="12" md="6" order-md="1" class="pa-lg-20px">
                            <div class="mb-4px mb-md-6px">
                                <tit-primary titSize="tit--sm" class="primary--text">01</tit-primary>
                            </div>
                            <div class="mb-12px mb-md-16px mb-lg-24px">
                                <tit-primary>직원 복지도 빠르고 쉽게</tit-primary>
                            </div>
                            <div>
                                <txt-primary class="line-height-15">
                                    대한문고 비즈몰로 직원용 상품권을 선물하세요.<br />
                                    대량발송기능으로 엑셀로 쉽고 빠르게 발송할 수 있습니다.
                                </txt-primary>
                            </div>
                        </v-col>
                    </v-row>
                </page-section-primary>
                <page-section-primary class="page-section--sm pb-0">
                    <v-row align="center" class="ma-lg-n20px">
                        <v-col cols="12" md="6" class="pa-lg-20px">
                            <v-img :aspect-ratio="580 / 340" src="/images/main/main-img2.jpg" />
                        </v-col>
                        <v-col cols="12" md="6" class="pa-lg-20px">
                            <div class="mb-4px mb-md-6px">
                                <tit-primary titSize="tit--sm" class="primary--text">02</tit-primary>
                            </div>
                            <div class="mb-12px mb-md-16px mb-lg-24px">
                                <tit-primary>선물 발송도 빠르고 쉽게</tit-primary>
                            </div>
                            <div>
                                <txt-primary class="line-height-15">
                                    대량 발송을 통한 빠른 선물 발송 서비스로 쉽게 발송 가능하며 <br class="d-none d-md-block" />
                                    템플릿 기능으로 발송 내용을 꾸며 더 감동적인 선물을 할 수 있습니다.
                                </txt-primary>
                            </div>
                        </v-col>
                    </v-row>
                </page-section-primary>
                <page-section-primary class="page-section--sm pb-0">
                    <v-row align="center" class="ma-lg-n20px">
                        <v-col cols="12" md="6" order-md="2" class="pa-lg-20px">
                            <v-img :aspect-ratio="580 / 340" src="/images/main/main-img3.jpg" />
                        </v-col>
                        <v-col cols="12" md="6" order-md="1" class="pa-lg-20px">
                            <div class="mb-4px mb-md-6px">
                                <tit-primary titSize="tit--sm" class="primary--text">03</tit-primary>
                            </div>
                            <div class="mb-12px mb-md-16px mb-lg-24px">
                                <tit-primary>발송내역 확인으로 더 쉽게</tit-primary>
                            </div>
                            <div>
                                <txt-primary class="line-height-15">
                                    발송 내역 확인으로 발송 증빙을 더 쉽게 확인가능하며 <br class="d-none d-md-block" />
                                    폐기된 상품권은 캐시로 전환되어 재발송이 가능합니다.
                                </txt-primary>
                            </div>
                        </v-col>
                    </v-row>
                </page-section-primary>
            </template>
        </page-section-primary>

        <page-section-primary class="page-section--last pt-0">
            <v-card img="/images/main/main-card.jpg" class="rounded-0 d-flex align-center h-300px">
                <v-container class="py-20px py-lg-30px text-center">
                    <div class="pb-16px pb-md-24px">
                        <tit-primary class="white--text">쉽고 빠른 대량상품권 발송 대한문고 비즈몰을 이용하세요</tit-primary>
                    </div>
                    <v-btn v-bind="{ ...btn_primary4 }" large class="v-btn--width-fixed" to="/buying">서비스 이용하기</v-btn>
                </v-container>
            </v-card>
        </page-section-primary>

        <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup" />
    </client-page>
</template>

<script>
import { btn_primary4 } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

// import MainVisual from "@/components/client/main/main-visual.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,

        // MainVisual,
        PageSectionPrimary,
        LogoPrimary,
        TitPrimary,
        TxtPrimary,
    },
    data: () => ({
        btn_primary4,

        popups: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.app--main::v-deep {
    #contents {
        padding-top: 0 !important;
    }
}

.main-visual {
    display: flex;
    align-items: center;
    height: 480px;
    background-image: -webkit-linear-gradient(to right, #f7f7f8, #c5c8f1);
    background-image: -ms-linear-gradient(to right, #f7f7f8, #c5c8f1);
    background-image: linear-gradient(to right, #f7f7f8, #c5c8f1);
    background-color: #c5c8f1;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        height: 340px;
    }
}
@media (min-width: 1024px) {
    .main-visual {
        height: 600px;
    }
}
@media (min-width: 1200px) {
}
</style>
