<template>
    <page-section v-bind="$attrs">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </page-section>
</template>

<script>
import PageSection from "@/sets/styles/pages/page-section.vue";
import TitWrapTertiary from "@/components/publish/parents/typography/tit-wrap-tertiary.vue";

export default {
    props: {
        titWrapClass: { type: String },
    },
    components: {
        PageSection,
        TitWrapTertiary,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped></style>
