<template>
    <footer-body class="footer-body--basic" :bodyRowAttrs="{ align: 'center', class:'row--xl' }" :bodyLeftAttrs="{ cols: '12', md: 'auto' }" :bodyRightAttrs="{ cols: '12', md: '' }">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </footer-body>
</template>

<script>
import FooterBody from "@/sets/styles/footers/footer-body.vue";

export default {
    props: {},
    components: {
        FooterBody,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer-body--basic {
    padding: 30px 0;
    border-top: 1px solid var(--v-grey-lighten4);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-body--basic {
        padding: 48px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
