import Vue from "vue";
import Vuetify from "vuetify";
import VCurrencyField from "v-currency-field";

// v-currency-field
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#C5C8F1",
                lighten4: "#888EE2",
                lighten3: "#5B63D7",
                lighten2: "#2E38BC",
                lighten1: "#2830A4",
                base: "#22298B",
                darken1: "#1C2272",
                darken2: "#161A5A",
                darken3: "#101341",
                darken4: "#0A0C29",
            },
            secondary: {
                lighten5: "#E3ECFD",
                lighten4: "#D3E0F6",
                lighten3: "#A5BEED",
                lighten2: "#6994E1",
                lighten1: "#5081DC",
                base: "#366FD7",
                darken1: "#2860C7",
                darken2: "#2254AD",
                darken3: "#1D4794",
                darken4: "#183B7A",
            },
            accent: {
                lighten5: "#E5ECFF",
                lighten4: "#BFD0FF",
                lighten3: "#94B1FF",
                lighten2: "#6991FF",
                lighten1: "#497AFF",
                base: "#2962FF",
                darken1: "#245AFF",
                darken2: "#1F50FF",
                darken3: "#1946FF",
                darken4: "#0F34FF",
            },
            grey: {
                lighten5: "#f7f7f7",
                lighten4: "#ececec",
                lighten3: "#ddd",
                lighten2: "#aaa",
                lighten1: "#999",
                base: "#666",
                darken1: "#555",
                darken2: "#444",
                darken3: "#333",
                darken4: "#111",
            },
            red: {
                base: "#F44336",
                lighten1: "#E35F48",
            },
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
