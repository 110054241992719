var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-items"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_vm._t("default"), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-fit', {
    attrs: {
      "small": "",
      "to": "/mypage/point-charging"
    }
  }, [_vm.icon ? _c('u-icon', {
    class: _vm.text ? 'mr-4px' : '',
    attrs: {
      "size": "small"
    }
  }, [_vm._v("person_outline")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("마이페이지")]) : _vm._e()], 1)], 1) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-fit', {
    attrs: {
      "small": "",
      "to": "/join"
    }
  }, [_vm.icon ? _c('u-icon', {
    class: _vm.text ? 'mr-4px' : '',
    attrs: {
      "size": "small"
    }
  }, [_vm._v("person_add_alt")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("회원가입")]) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "grey lighten-3",
    attrs: {
      "vertical": ""
    }
  })], 1), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-fit', {
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm.icon ? _c('u-icon', {
    class: _vm.text ? 'mr-4px' : '',
    attrs: {
      "size": "small"
    }
  }, [_vm._v("logout")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("로그아웃")]) : _vm._e()], 1)], 1) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-btn-text-fit', {
    attrs: {
      "small": "",
      "to": "/login"
    }
  }, [_vm.icon ? _c('u-icon', {
    class: _vm.text ? 'mr-4px' : '',
    attrs: {
      "size": "small"
    }
  }, [_vm._v("login")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("로그인")]) : _vm._e()], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }