import axios from "@/plugins/axios";

import options from "./options";
import supplies from "./supplies";

let url = "/api/console/shop/products";

export default {
    options,
    supplies,

    getProducts(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    getProduct(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    postProduct(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    putProduct(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    deleteProduct(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
