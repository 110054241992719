<template>
    <lnb class="lnb--topline-border">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </lnb>
</template>

<script>
import Lnb from "@/components/publish/styles/headers/lnb.vue";

export default {
    props: {},
    components: {
        Lnb,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.lnb--topline-border {
    ::v-deep {
        top: calc(var(--header-body-height) - 4px);
        padding-top: 4px;
        &:not(.lnb--empty)::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            display: block;
            width: 0;
            height: 4px;
            opacity: 0;
            background-color: var(--v-primary-base);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        > li {
            border: 1px solid var(--border-color);
            border-top: 0;
            > a {
                &:hover {
                    color: var(--v-primary-base);
                }
            }
        }
    }
}
.gnb__item.active {
    .lnb--topline-border:not(.lnb--empty) {
        &::before {
            width: 100%;
            opacity: 1;
        }
    }
}
</style>
