<template>
    <u-divider-group class="divider-group--lg divider-group--dark">
        <li v-for="link in links" :key="link.title" class="font-weight-medium">
            <a v-if="link.href" :href="link.href" target="_blank" :class="link.color ? link.color : color">{{ link.title }}</a>
            <router-link v-else :to="link.to" :class="link.color ? link.color : color">{{ link.title }}</router-link>
        </li>
    </u-divider-group>
</template>

<script>
import UDividerGroup from "@/components/publish/styles/groups/u-divider-group.vue";

export default {
    props: {
        color: { type: String, default: "grey--text" },
    },
    components: {
        UDividerGroup,
    },
    data() {
        return {
            links: [
                { title: "이용약관", to: "/terms" },
                { title: "개인정보처리방침", to: "/privacy-policy", color: "primary--text" },
                { title: "고객센터", to: "/" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
