var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer-head', {
    staticClass: "footer-head--basic",
    attrs: {
      "headRowAttrs": {
        align: 'center',
        justify: 'center'
      },
      "headLeftAttrs": {
        cols: '12',
        md: '',
        order: '2',
        'order-md': '1'
      },
      "headRightAttrs": {
        cols: '12',
        md: 'auto',
        order: '1',
        'order-md': '2',
        class: 'd-flex'
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }