<template>
    <div v-bind="$attrs" class="tit-wrap">
        <slot />
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Default
.tit-wrap {
    padding-bottom: var(--tit-wrap-padding-bottom);
    &--xs {
        padding-bottom: var(--tit-wrap-padding-bottom-xs);
    }
    &--sm {
        padding-bottom: var(--tit-wrap-padding-bottom-sm);
    }
    &--lg {
        padding-bottom: var(--tit-wrap-padding-bottom-lg);
    }
    .tit + .txt {
        margin-top: 6px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .tit-wrap {
        .tit + .txt {
            margin-top: 10px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
