<template>
    <u-txt-default v-bind="$attrs" :class="txtSize"><slot /></u-txt-default>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        txtSize: { type: String, default: "" },
    },
    components: {
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
