<template>
    <header-head class="header-head--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </header-head>
</template>

<script>
import HeaderHead from "@/sets/styles/headers/header-head.vue";

export default {
    props: {},
    components: {
        HeaderHead,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.header-head--basic {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.scroll-active {
    .header-head--basic {
        background-color: rgba(255, 255, 255, 0.8);
    }
}
</style>
