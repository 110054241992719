<template>
    <div class="mobile-nav">
        <div class="mobile-nav__contents">
            <slot />
        </div>
        <div class="mobile-nav__overlay"></div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$nextTick(function () {
                this.header();
            });
        },

        // header
        header: function () {
            // gnbMoLink, gnbMoSubLink 클릭 시 mobileNav 닫기
            var mobileNav = document.querySelector(".mobile-nav");
            var gnbMoItem = document.querySelectorAll(".gnb-mo__item");
            var gnbMoLink = document.querySelectorAll(".gnb-mo__link");
            var gnbMoSubLink = document.querySelectorAll(".gnb-mo__sub-link");
            function mobileNavClose() {
                mobileNav.classList.remove("open");
                for (var i = 0; i < gnbMoItem.length; i++) {
                    gnbMoItem[i].classList.remove("open");
                }
            }
            for (var i = 0; i < gnbMoLink.length; i++) {
                gnbMoLink[i].addEventListener("click", mobileNavClose);
            }
            for (var i = 0; i < gnbMoSubLink.length; i++) {
                gnbMoSubLink[i].addEventListener("click", mobileNavClose);
            }

            // gnbMoArrow 클릭 시 sub 열고 닫기
            var gnbMoArrow = document.querySelectorAll(".gnb-mo__arrow");
            function gnbMoSubOpen(el) {
                var target = el.currentTarget;
                target.parentNode.classList.toggle("open");
            }
            gnbMoArrow.forEach(function (el) {
                el.addEventListener("click", gnbMoSubOpen);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-nav {
    position: fixed;
    z-index: 100;
    &,
    &__contents,
    &__overlay {
        transition: all ease-out 0.2s;
    }
    &__contents {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        max-width: 500px;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(100%);
    }
    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        z-index: -90;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        visibility: hidden;
    }
    &.open {
        .mobile-nav {
            &__contents {
                transform: translateX(0);
                transition: all ease-out 0.2s;
            }
            &__overlay {
                z-index: 90;
                opacity: 1;
                visibility: visible;
                transition: all ease-out 0.2s;
            }
        }
    }
}
</style>
