import axios from "@/plugins/axios";
// import * as example from "./example";

let url = "/api/console/logs";

export default {
    // ...example,
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    }
}