<template>
    <logo>
        <slot />
    </logo>
</template>

<script>
import Logo from "@/components/publish/styles/headers/logo.vue";

export default {
    props: {},
    components: {
        Logo,
    },
    data() {
        return {
            to: "",
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
