import axios from "@/plugins/axios";

let url = "/api/console/gshop/orders";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
	get(data){
        return axios.get(`${url}/${data._id}`, data).then(result => result.data);
    },
}
