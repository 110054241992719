var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-divider-group', {
    staticClass: "divider-group--lg divider-group--dark"
  }, _vm._l(_vm.links, function (link) {
    return _c('li', {
      key: link.title,
      staticClass: "font-weight-medium"
    }, [link.href ? _c('a', {
      class: link.color ? link.color : _vm.color,
      attrs: {
        "href": link.href,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(link.title))]) : _c('router-link', {
      class: link.color ? link.color : _vm.color,
      attrs: {
        "to": link.to
      }
    }, [_vm._v(_vm._s(link.title))])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }