<template>
    <u-tit-wrap-default v-bind="$attrs" :class="[titWrapClass, titWrapSize, titWrapAlign]">
        <div v-if="this.$slots['subtit']" class="overflow-hidden">
            <u-tit-subtit class="tit--xs" data-aos="fade-up" data-aos-delay="100"><slot name="subtit" /></u-tit-subtit>
        </div>
        <div v-if="this.$slots['tit']" class="overflow-hidden">
            <u-tit-default :class="[titClass, titSize]" data-aos="fade-up" data-aos-delay="200"><slot name="tit" /></u-tit-default>
        </div>
        <div v-else class="overflow-hidden">
            <u-tit-default :class="[titClass, titSize]" data-aos="fade-up" data-aos-delay="200"><slot /></u-tit-default>
        </div>
        <div v-if="this.$slots['txt']" class="overflow-hidden mt-8px">
            <u-txt-default :class="[txtClass, txtSize]" data-aos="fade-up" data-aos-delay="300"><slot name="txt" /></u-txt-default>
        </div>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        titWrapClass: { type: String, default: "" },
        titWrapSize: { type: String, default: "" },
        titWrapAlign: { type: String, default: "text-center" },
        titClass: { type: String, default: "" },
        titSize: { type: String, default: "tit--lg" },
        txtClass: { type: String, default: "" },
        txtSize: { type: String, default: "" },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
