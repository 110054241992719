<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            <u-txt-default class="txt--sm">
                <span class="font-weight-medium">{{ info.title }}</span> <span class="grey--text text--lighten-1">{{ info.text }}</span>
            </u-txt-default>
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "회사명", text: "주식회사 대한문고" },
                { title: "주소", text: "서울특별시 강서구 공항대로 228, 리더스타워 7층" },
                { title: "대표번호", text: "1800-9510" },
                { title: "FAX", text: "02-6442-9520" },
                { title: "사업자등록번호", text: "846-81-00822", clear: true },
                { title: "통신판매업신고번호", text: "제_2020-서울강서-1979호" },
                { title: "대표자", text: "우상덕" },
                { title: "개인정보관리책임자", text: "채선영" },
                { title: "E-mail", text: "help@daehanbooks.co.kr", clear: true },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
