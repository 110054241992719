var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('div', {
    staticClass: "main-visual"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "auto",
      "order-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mb-20px mb-md-0",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 582 : 300,
      "src": "/images/main/mainvisual-img.png"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center text-md-left",
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "mb-12px mb-md-14px"
  }, [_c('v-img', {
    staticClass: "mx-auto mx-md-0",
    staticStyle: {
      "width": "120px",
      "height": "30px"
    },
    attrs: {
      "src": "/images/daehanbooks-logo.png"
    }
  })], 1), _c('div', {
    staticClass: "mb-16px mb-md-20px mb-lg-30px"
  }, [_c('tit-primary', {
    attrs: {
      "titSize": "font-size-30 font-size-lg-56"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("비즈몰")]), _vm._v(" 공식오픈")])], 1), _c('div', [_c('txt-primary', {
    staticClass: "txt--dark line-height-15",
    attrs: {
      "txtSize": "font-size-16 font-size-lg-24"
    }
  }, [_vm._v(" B2B 사업자를 위한 대한문고 비즈몰이 오픈했습니다."), _c('br'), _vm._v(" 더 쉽고 편리하게 기업을 홍보를 하고 직원들에게 복지를 선물하세요. ")])], 1)])], 1)], 1)], 1), _c('page-section-primary', {
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('page-section-primary', {
          staticClass: "page-section--sm py-0"
        }, [_c('v-row', {
          staticClass: "ma-lg-n20px",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-lg-20px",
          attrs: {
            "cols": "12",
            "md": "6",
            "order-md": "2"
          }
        }, [_c('v-img', {
          attrs: {
            "aspect-ratio": 580 / 340,
            "src": "/images/main/main-img.jpg"
          }
        })], 1), _c('v-col', {
          staticClass: "pa-lg-20px",
          attrs: {
            "cols": "12",
            "md": "6",
            "order-md": "1"
          }
        }, [_c('div', {
          staticClass: "mb-4px mb-md-6px"
        }, [_c('tit-primary', {
          staticClass: "primary--text",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("01")])], 1), _c('div', {
          staticClass: "mb-12px mb-md-16px mb-lg-24px"
        }, [_c('tit-primary', [_vm._v("직원 복지도 빠르고 쉽게")])], 1), _c('div', [_c('txt-primary', {
          staticClass: "line-height-15"
        }, [_vm._v(" 대한문고 비즈몰로 직원용 상품권을 선물하세요."), _c('br'), _vm._v(" 대량발송기능으로 엑셀로 쉽고 빠르게 발송할 수 있습니다. ")])], 1)])], 1)], 1), _c('page-section-primary', {
          staticClass: "page-section--sm pb-0"
        }, [_c('v-row', {
          staticClass: "ma-lg-n20px",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-lg-20px",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-img', {
          attrs: {
            "aspect-ratio": 580 / 340,
            "src": "/images/main/main-img2.jpg"
          }
        })], 1), _c('v-col', {
          staticClass: "pa-lg-20px",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "mb-4px mb-md-6px"
        }, [_c('tit-primary', {
          staticClass: "primary--text",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("02")])], 1), _c('div', {
          staticClass: "mb-12px mb-md-16px mb-lg-24px"
        }, [_c('tit-primary', [_vm._v("선물 발송도 빠르고 쉽게")])], 1), _c('div', [_c('txt-primary', {
          staticClass: "line-height-15"
        }, [_vm._v(" 대량 발송을 통한 빠른 선물 발송 서비스로 쉽게 발송 가능하며 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v(" 템플릿 기능으로 발송 내용을 꾸며 더 감동적인 선물을 할 수 있습니다. ")])], 1)])], 1)], 1), _c('page-section-primary', {
          staticClass: "page-section--sm pb-0"
        }, [_c('v-row', {
          staticClass: "ma-lg-n20px",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-lg-20px",
          attrs: {
            "cols": "12",
            "md": "6",
            "order-md": "2"
          }
        }, [_c('v-img', {
          attrs: {
            "aspect-ratio": 580 / 340,
            "src": "/images/main/main-img3.jpg"
          }
        })], 1), _c('v-col', {
          staticClass: "pa-lg-20px",
          attrs: {
            "cols": "12",
            "md": "6",
            "order-md": "1"
          }
        }, [_c('div', {
          staticClass: "mb-4px mb-md-6px"
        }, [_c('tit-primary', {
          staticClass: "primary--text",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("03")])], 1), _c('div', {
          staticClass: "mb-12px mb-md-16px mb-lg-24px"
        }, [_c('tit-primary', [_vm._v("발송내역 확인으로 더 쉽게")])], 1), _c('div', [_c('txt-primary', {
          staticClass: "line-height-15"
        }, [_vm._v(" 발송 내역 확인으로 발송 증빙을 더 쉽게 확인가능하며 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v(" 폐기된 상품권은 캐시로 전환되어 재발송이 가능합니다. ")])], 1)])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('page-section-primary', {
    staticClass: "page-section--last pt-0"
  }, [_c('v-card', {
    staticClass: "rounded-0 d-flex align-center h-300px",
    attrs: {
      "img": "/images/main/main-card.jpg"
    }
  }, [_c('v-container', {
    staticClass: "py-20px py-lg-30px text-center"
  }, [_c('div', {
    staticClass: "pb-16px pb-md-24px"
  }, [_c('tit-primary', {
    staticClass: "white--text"
  }, [_vm._v("쉽고 빠른 대량상품권 발송 대한문고 비즈몰을 이용하세요")])], 1), _c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "large": "",
      "to": "/buying"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary4), false), [_vm._v("서비스 이용하기")])], 1)], 1)], 1), _vm._l(_vm.popups, function (popup) {
    return _c('popup-layer', {
      key: popup._id,
      attrs: {
        "popup": popup
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }