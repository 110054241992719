var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-footer-basic', [_c('u-footer-head-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('footer-link')];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-btn', _vm._b({
          attrs: {
            "block": "",
            "target": "_blank",
            "href": "https://www.daehanbooks.co.kr/"
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("대한문고 쇼핑몰로 이동")])];
      },
      proxy: true
    }])
  }), _c('u-footer-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', {
          staticClass: "ml-md-auto"
        }, [_vm._v("비즈몰")])];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('footer-info')], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-img', {
          attrs: {
            "width": "auto",
            "src": "/images/footer-logo.png"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--sm mb-4px mb-md-8px"
        }, [_vm._v("상표등록 출원번호 4020180121475, 4020210131548 무단 도용 시 법적 불이익을 받습니다.")]), _c('u-txt-default', {
          staticClass: "txt--sm"
        }, [_vm._v("ⓒ 2024. BOOK Inc. all right reserved.")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }