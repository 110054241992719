<template>
    <u-footer-basic>
        <u-footer-head-basic>
            <template #left>
                <footer-link />
            </template>
            <template #right>
                <v-btn v-bind="{ ...btn_primary }" block target="_blank" href="https://www.daehanbooks.co.kr/">대한문고 쇼핑몰로 이동</v-btn>
            </template>
        </u-footer-head-basic>
        <u-footer-body-basic>
            <template #left>
                <logo-primary class="ml-md-auto">비즈몰</logo-primary>
            </template>
            <template #center>
                <v-row class="row--sm">
                    <!-- <v-col cols="12">
                        <u-txt-default class="txt--sm txt--dark font-weight-bold">대한문고</u-txt-default>
                    </v-col> -->
                    <v-col cols="12">
                        <footer-info />
                    </v-col>
                    <v-col cols="auto">
                        <v-img width="auto" src="/images/footer-logo.png" />
                    </v-col>
                    <v-col cols="12">
                        <u-txt-default class="txt--sm mb-4px mb-md-8px">상표등록 출원번호 4020180121475, 4020210131548 무단 도용 시 법적 불이익을 받습니다.</u-txt-default>
                        <u-txt-default class="txt--sm">ⓒ 2024. BOOK Inc. all right reserved.</u-txt-default>
                    </v-col>
                </v-row>
            </template>
        </u-footer-body-basic>
    </u-footer-basic>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";

import UFooterBasic from "@/sets/styles/footers/u-footer-basic.vue";
import UFooterHeadBasic from "@/sets/styles/footers/u-footer-head-basic.vue";
import UFooterBodyBasic from "@/sets/styles/footers/u-footer-body-basic.vue";
import UFooterFootBasic from "@/sets/styles/footers/u-footer-foot-basic.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import FooterLink from "@/components/publish/styles/footers/footer-link.vue";
import FooterInfo from "@/components/publish/styles/footers/footer-info.vue";
// import IconBlog from "@/components/publish/styles/icons/icon-blog.vue";
// import IconYoutube from "@/components/publish/styles/icons/icon-youtube.vue";
// import IconFacebook from "@/components/publish/styles/icons/icon-facebook.vue";
// import IconInsta from "@/components/publish/styles/icons/icon-insta.vue";

export default {
    components: {
        LogoPrimary,

        UFooterBasic,
        UFooterHeadBasic,
        UFooterBodyBasic,
        UFooterFootBasic,

        UTxtDefault,
        UTxtMedium,
        FooterLink,
        FooterInfo,
        // IconBlog,
        // IconYoutube,
        // IconFacebook,
        // IconInsta,
    },
    data() {
        return {
            btn_primary,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
