<template>
    <v-app>
        <!-- S: BANNER -->
        <slot name="bandBanner" />
        <!-- E: BANNER -->

        <!-- S: HEADER -->
        <header-primary v-if="$vuetify.breakpoint.xlOnly" />
        <template v-else>
            <mobile-header-primary />
            <mobile-nav-primary />
        </template>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="contentsHead" />
            <div id="contents">
                <slot />
            </div>
            <slot name="contentsFoot" />
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <footer-primary />
        <!-- E: FOOTER -->

        <btn-top-primary />
    </v-app>
</template>

<script>
import HeaderPrimary from "@/sets/parents/headers/header-primary.vue";
import MobileHeaderPrimary from "@/sets/parents/headers/mobile-header-primary.vue";
import MobileNavPrimary from "@/sets/parents/headers/mobile-nav-primary.vue";
import FooterPrimary from "@/sets/parents/footers/footer-primary.vue";
import BtnTopPrimary from "@/components/publish/parents/buttons/btn-top-primary.vue";

export default {
    components: {
        HeaderPrimary,
        MobileHeaderPrimary,
        MobileNavPrimary,
        FooterPrimary,
        BtnTopPrimary,
    },
    props: {
        hasCustomAside: { type: Boolean, default: false },
        disableScrollTop: { type: Boolean, default: false },
    },
    data: () => ({
        loading: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.$nextTick(() => {
                    setTimeout(() => {
                        AOS.init({
                            once: true,
                            duration: 800,
                            throttleDelay: 100,
                        });
                    }, 100);
                });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
